<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="mobile"
                    :label="$t('pageKey121', '电话')"
                >
                    <el-input
                        v-model="queryFormModel.mobile"
                        :placeholder="$t('pageKey129', '请输入电话')"
                    />
                </el-form-item>
                <el-form-item
                    prop="buyerStatus"
                    :label="$t('pageKey468', '买家状态')"
                >
                    <dictionaries-picker
                        type="buyerStatus"
                        v-model="queryFormModel.buyerStatus"
                        :placeholder="$t('pageKey101', '请选择')"
                    />
                </el-form-item>
                <el-form-item
                    prop="buyerLevelId"
                    :label="$t('pageKey469', '会员等级')"
                >
                    <el-select
                        v-model="queryFormModel.buyerLevelId"
                        :placeholder="$t('pageKey101', '请选择')"
                    >
                        <el-option
                            v-for="item in buyerLevelList"
                            :key="item.id"
                            :label="item.levelName"
                            :value="item.id + ''"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item
                    prop="referenceBuyer"
                    :label="$t('pageKey470', '推荐人')"
                >
                    <el-input
                        v-model="queryFormModel.referenceBuyer"
                        :placeholder="$t('pageKey471', '请输入推荐人')"
                    />
                </el-form-item>
                <el-form-item
                    prop="comments"
                    :label="$t('pageKey107', '备注')"
                >
                    <el-input
                        v-model="queryFormModel.comments"
                        :placeholder="$t('pageKey472', '请输入备注')"
                    />
                </el-form-item>
                <el-form-item
                    prop="insertTime"
                    :label="$t('pageKey473', '注册时间')"
                >
                    <el-date-picker
                        :picker-options="$utils.CommonConfig.pickerOptions"
                        v-model="queryFormModel.insertTime"
                        type="daterange"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        :range-separator="$t('pageKey475', '至')"
                        :start-placeholder="$t('pageKey474', '开始日期')"
                        :end-placeholder="$t('pageKey476', '结束日期')"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        {{ $t('pageKey9', '查询') }}
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        {{ $t('pageKey10', '重置') }}
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <!--                    <el-button
                                            type="success"
                                            icon="el-icon-plus"
                                            size="small"
                                            @click="onBatchChangeBuyerLevel"
                                            v-if="$power(['buyer:setBuyerLevel'])"
                                        >
                                            批量设置会员等级
                                        </el-button>
                                        <el-button
                                            type="success"
                                            icon="el-icon-plus"
                                            size="small"
                                            @click="onBatchChangeFrontDecoration"
                                            v-if="$power(['buyer:setFrontDecoration'])"
                                        >
                                            批量设置首页模板
                                        </el-button>-->
                    <el-button
                        icon="el-icon-refresh-right"
                        type="success"
                        size="small"
                        @click="onSyncBuyer"
                        v-if="userData.shop.isSyncPospal"
                    >
                        {{ $t('pageKey477', '同步银豹会员') }}
                    </el-button>
                    <el-button
                        type="success"
                        icon="el-icon-download"
                        size="small"
                        @click="exportExcel"
                    >
                        {{ $t('pageKey211', '导出') }}
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
                @selection-change="onTableSelectionChange"
                :empty-text="$t('pageKey977', '暂无数据')"
            >
                <el-table-column
                    type="selection"
                    width="36"
                />
                <el-table-column
                    prop="userName"
                    :label="$t('pageKey63', '用户名')"
                    min-width="100"
                />
                <el-table-column
                    prop="buyerLevelName"
                    :label="$t('pageKey469', '会员等级')"
                    min-width="100"
                />
                <el-table-column
                    prop="nickName"
                    :label="$t('pageKey478', '昵称')"
                    min-width="100"
                />
                <el-table-column
                    :label="$t('pageKey479', '头像')"
                    width="120"
                >
                    <template slot-scope="scope">
                        <ImageList
                            :data="scope.row.avatar"
                            :thumbnail-width="0"
                            :preview-width="0"
                            background-size="auto"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    prop="mobile"
                    :label="$t('pageKey121', '电话')"
                    min-width="100"
                >
                    <template slot-scope="scope">
                        {{ scope.row.countryCode }}-{{ scope.row.mobile }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="validPoint"
                    :label="$t('pageKey258', '积分')"
                    min-width="100"
                    v-if="(userData.shop.buyerConfig & 8) !== 0"
                />
                <el-table-column
                    prop="validGrowthValue"
                    :label="$t('pageKey480', '成长值')"
                    min-width="100"
                    v-if="(userData.shop.buyerConfig & 2) !== 0"
                />
                <el-table-column
                    prop="purseBalance"
                    :label="$t('pageKey889', '金额')"
                    min-width="100"
                />
                <el-table-column
                    :label="$t('pageKey473', '注册时间')"
                    min-width="150"
                >
                    <template slot-scope="scope">
                        {{ scope.row.insertTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    :label="$t('pageKey107', '备注')"
                    min-width="100"
                >
                    <template slot-scope="scope">
                        {{ scope.row.comments }}
                    </template>
                </el-table-column>
                <el-table-column
                    :label="$t('pageKey470', '推荐人')"
                    min-width="100"
                >
                    <template slot-scope="scope">
                        {{ scope.row.referenceDistributorName }}
                    </template>
                </el-table-column>
                <el-table-column
                    :label="$t('pageKey481', '买家来源')"
                    min-width="100"
                >
                    <template slot-scope="scope">
                        {{ scope.row.buyerSourceChannelName }}
                        <div>
                            {{ scope.row.buyerSourceName }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="firstPageDecorationName"
                    :label="$t('pageKey482', '首页模板')"
                    min-width="100"
                />
                <el-table-column
                    prop="buyerStatusName"
                    :label="$t('pageKey468', '买家状态')"
                    min-width="100"
                />
                <el-table-column
                    fixed="right"
                    :label="$t('pageKey13', '操作')"
                    width="120"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="onEdit(scope.row)"
                            v-if="$power(['buyer:editInfo'])"
                        >
                            {{ $t('pageKey16', '编辑') }}
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onPlatEditPoint(scope.row)"
                            v-if="(userData.shop.buyerConfig & 8) !== 0"
                        >
                            {{ $t('pageKey483', '积分变更') }}
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onBuyerPrepaid(scope.row)"
                            v-if="$power(['buyer:buyerPrepaid'])"
                        >
                            充值
                        </el-button>
                        <!--
                                    <el-button
                                        type="text"
                                        size="small"
                                        @click="onChangeBuyerLevel(scope.row)"
                                        v-if="$power(['buyer:setBuyerLevel'])"
                                    >
                                        设置会员等级
                                    </el-button>
                                    <el-button
                                        type="text"
                                        size="small"
                                        @click="onChangeFrontDecoration(scope.row)"
                                        v-if="$power(['buyer:setFrontDecoration'])"
                                    >
                                        设置首页模板
                                    </el-button>
                                    <el-button
                                        type="text"
                                        size="small"
                                        @click="onOrderImport(scope.row)"
                                        v-if="$power(['buyer:orderImport'])"
                                    >
                                        订单导入
                                    </el-button>
                                    <el-button
                                        type="text"
                                        size="small"
                                        @click="onCreateOrder(scope.row)"
                                    >
                                        创建订单
                                    </el-button>-->
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>

        <!-- 弹框 -->
        <!-- 设置会员等级 -->
        <el-dialog
            :title="$t('pageKey484', '设置会员等级')"
            center
            width="400px"
            :visible.sync="setLevelDialog.isVisible"
            @closed="onSetLevelDialogCancel"
        >
            <el-form
                ref="addForm"
                size="small"
                :model="setLevelDialog.formModel"
                :rules="setLevelDialog.formRules"
            >
                <el-form-item
                    prop="buyerLevelId"
                    :label="$t('pageKey469', '会员等级')"
                    label-width="6em"
                >
                    <el-select
                        v-model="setLevelDialog.formModel.buyerLevelId"
                        :placeholder="$t('pageKey101', '请选择')"
                    >
                        <el-option
                            v-for="item in buyerLevelList"
                            :key="item.id"
                            :label="item.levelName"
                            :value="item.id + ''"
                        />
                    </el-select>
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onSetLevelDialogConfirm"
                >
                    {{ $t('pageKey19', '确 定') }}
                </el-button>
                <el-button
                    size="small"
                    @click="onSetLevelDialogCancel"
                >
                    {{ $t('pageKey20', '取 消') }}
                </el-button>
            </div>
        </el-dialog>

        <!--订单导入-->
        <el-dialog
            :title="$t('pageKey485', '订单导入')"
            center
            width="600px"
            :visible.sync="orderImportDialog.isVisible"
            @closed="onOrderImportDialogCancel"
        >
            <el-form
                ref="addForm"
                size="small"
            >
                <el-form-item
                    :label="$t('pageKey469', '会员等级')+':'"
                    label-width="6em"
                >
                    {{ orderImportDialog.buyer.buyerLevelName }}
                </el-form-item>
                <el-form-item
                    :label="$t('pageKey121', '电话')+':'"
                    label-width="6em"
                >
                    {{ orderImportDialog.buyer.mobile }}
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    disabled
                >
                    {{ $t('pageKey486', '模板下载') }}
                </el-button>
                <el-upload
                    class="upload-demo dp-ib ma-l"
                    action="/ex/order/batchCreateOrderByExcel"
                    :data="{buyerId: this.orderImportDialog.buyer.id}"
                    :show-file-list="false"
                    accept=".xls,.xlsx"
                    :on-success="onOrderImportSuccess"
                    :on-error="onOrderImportError"
                >
                    <el-button
                        size="small"
                        type="primary"
                    >
                        {{ $t('pageKey487', '点击上传') }}
                    </el-button>
                </el-upload>
            </div>
        </el-dialog>

        <!-- 充值零钱 -->
        <el-dialog
            :title="$t('pageKey488', '零钱充值')"
            center
            width="600px"
            :visible.sync="buyerPrepaidDialog.isVisible"
            @closed="onBuyerPrepaidDialogCancel"
        >
            <el-form
                ref="buyerPrepaidForm"
                size="small"
                :model="buyerPrepaidDialog.formModel"
                :rules="buyerPrepaidDialog.formRules"
            >
                <el-form-item
                    prop="prepaidAmount"
                    :label="$t('pageKey489', '充值金额')"
                    label-width="6em"
                >
                    <el-input-number
                        controls-position="right"
                        :precision="2"
                        v-model.number="buyerPrepaidDialog.formModel.prepaidAmount"
                    />
                </el-form-item>
                <el-form-item
                    prop="cardPrice"
                    :label="$t('pageKey490', '实付金额')"
                    label-width="6em"
                >
                    <el-input-number
                        controls-position="right"
                        :precision="2"
                        v-model.number="buyerPrepaidDialog.formModel.cardPrice"
                    />
                </el-form-item>
                <el-form-item
                    prop="payVoucher"
                    :label="$t('pageKey491', '付款凭证')"
                    label-width="6em"
                >
                    <upload
                        validate-md5
                        action="/rs/attachment/uploadFileLibrary"
                        :limit="5"
                        v-model="buyerPrepaidDialog.formModel.payVoucher"
                    />
                </el-form-item>
                <el-form-item
                    prop="paymentComments"
                    :label="$t('pageKey107', '备注')"
                    label-width="6em"
                >
                    <el-input
                        type="textarea"
                        v-model="buyerPrepaidDialog.formModel.paymentComments"
                        auto-complete="off"
                    />
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onBuyerPrepaidDialogConfirm"
                >
                    {{ $t('pageKey19', '确 定') }}
                </el-button>
                <el-button
                    size="small"
                    @click="onBuyerPrepaidDialogCancel"
                >
                    {{ $t('pageKey20', '取 消') }}
                </el-button>
            </div>
        </el-dialog>

        <!--积分变更-->
        <el-dialog
            :title="$t('pageKey483', '积分变更')"
            center
            width="600px"
            :visible.sync="platEditPointDialog.isVisible"
            @closed="onPlatEditPointDialogCancel"
        >
            <el-form
                ref="platEditPointForm"
                size="small"
                :model="platEditPointDialog.formModel"
                :rules="platEditPointDialog.formRules"
            >
                <el-form-item
                    prop="pointNumber"
                    :label="$t('pageKey492', '积分变动值')"
                    label-width="8em"
                >
                    <el-input-number
                        controls-position="right"
                        :precision="0"
                        v-model.number="platEditPointDialog.formModel.pointNumber"
                    />
                </el-form-item>
                <el-form-item
                    prop="pointDesc"
                    :label="$t('pageKey493', '积分变动描述')"
                    label-width="8em"
                >
                    <el-input
                        type="textarea"
                        v-model="platEditPointDialog.formModel.pointDesc"
                        auto-complete="off"
                    />
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onPlatEditPointDialogConfirm"
                >
                    {{ $t('pageKey19', '确 定') }}
                </el-button>
                <el-button
                    size="small"
                    @click="onPlatEditPointDialogCancel"
                >
                    {{ $t('pageKey20', '取 消') }}
                </el-button>
            </div>
        </el-dialog>

        <!-- 设置首页模板 -->
        <el-dialog
            :title="$t('pageKey494', '设置首页模板')"
            center
            width="400px"
            :visible.sync="setFrontDecorationDialog.isVisible"
            @closed="onSetFrontDecorationDialogCancel"
        >
            <el-form
                ref="frontDecorationForm"
                size="small"
                :model="setFrontDecorationDialog.formModel"
                :rules="setFrontDecorationDialog.formRules"
            >
                <el-form-item
                    prop="firstPageDecorationId"
                    :label="$t('pageKey482', '首页模板')"
                    label-width="6em"
                >
                    <el-select
                        v-model="setFrontDecorationDialog.formModel.firstPageDecorationId"
                        :placeholder="$t('pageKey101', '请选择')"
                        clearable
                    >
                        <el-option
                            v-for="item in shopDecorationList"
                            :key="item.id"
                            :label="item.pageName"
                            :value="item.id + ''"
                        />
                    </el-select>
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onSetFrontDecorationDialogConfirm"
                >
                    {{ $t('pageKey19', '确 定') }}
                </el-button>
                <el-button
                    size="small"
                    @click="onSetFrontDecorationDialogCancel"
                >
                    {{ $t('pageKey20', '取 消') }}
                </el-button>
            </div>
        </el-dialog>

        <!-- 设置会员等级 -->
        <el-dialog
            :title="$t('pageKey16', '编辑')"
            center
            width="800px"
            :visible.sync="editInfoDialog.isVisible"
            @closed="onEditInfoDialogCancel"
        >
            <el-form
                ref="addForm"
                size="small"
                :model="editInfoDialog.formModel"
                :rules="editInfoDialog.formRules"
            >
                <el-form-item
                    prop="buyerLevelId"
                    :label="$t('pageKey469', '会员等级')"
                    label-width="6em"
                >
                    <el-select
                        v-model="editInfoDialog.formModel.buyerLevelId"
                        :placeholder="$t('pageKey101', '请选择')"
                    >
                        <el-option
                            v-for="item in buyerLevelList"
                            :key="item.id"
                            :label="item.levelName"
                            :value="item.id + ''"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item
                    prop="referenceId"
                    :label="$t('pageKey470', '推荐人')"
                    label-width="6em"
                >
                    {{ editInfoDialog.referenceBuyer.distributorName }}
                    <el-button-group>
                        <el-button
                            type="success"
                            icon="el-icon-plus"
                            size="small"
                            @click="onAddSelect(2)"
                        >
                            {{ $t('pageKey495', '选择推荐人') }}
                        </el-button>
                    </el-button-group>
                </el-form-item>
                <el-form-item
                    prop="comments"
                    :label="$t('pageKey107', '备注')"
                    label-width="6em"
                >
                    <el-input
                        type="textarea"
                        v-model="editInfoDialog.formModel.comments"
                        auto-complete="off"
                    />
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onEditInfoDialogConfirm"
                >
                    {{ $t('pageKey19', '确 定') }}
                </el-button>
                <el-button
                    size="small"
                    @click="onEditInfoDialogCancel"
                >
                    {{ $t('pageKey20', '取 消') }}
                </el-button>
            </div>
        </el-dialog>

        <!--选择会员-->
        <el-dialog
            :title="$t('pageKey250', '选择会员')"
            center
            width="1200px"
            :visible.sync="selectDialog.isVisible"
            @closed="onSelectCancel"
        >
            <!-- 表单区 -->
            <el-form
                ref="selectDialogQueryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="selectDialog.queryFormModel"
            >
                <el-form-item
                    prop="userName"
                    :label="$t('pageKey496', '买家账号')"
                >
                    <el-input
                        v-model="selectDialog.queryFormModel.userName"
                        :placeholder="$t('pageKey497', '请输入昵称')"
                    />
                </el-form-item>
                <el-form-item
                    prop="mobile"
                    :label="$t('pageKey121', '电话')"
                >
                    <el-input
                        v-model="selectDialog.queryFormModel.mobile"
                        :placeholder="$t('pageKey129', '请输入电话')"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onSelectDialogQuery()"
                    >
                        {{ $t('pageKey9', '查询') }}
                    </el-button>
                    <el-button
                        type="success"
                        @click="onResetSelectDialog"
                    >
                        {{ $t('pageKey10', '重置') }}
                    </el-button>
                </el-form-item>
            </el-form>

            <!-- 内容 -->
            <div class="body">
                <!-- 表格 -->
                <el-table
                    stripe
                    border
                    size="small"
                    :data="selectDialog.tableData"
                    style="width: 100%"
                    height="400"
                    ref="selectDialogTable"
                >
                    <el-table-column
                        prop="userName"
                        :label="$t('pageKey496', '买家账号')"
                        min-width="100"
                    />
                    <el-table-column
                        prop="buyerLevelName"
                        :label="$t('pageKey469', '会员等级')"
                        min-width="100"
                    />
                    <el-table-column
                        prop="nickName"
                        :label="$t('pageKey478', '昵称')"
                        min-width="100"
                    />
                    <el-table-column
                        prop="mobile"
                        :label="$t('pageKey121', '电话')"
                        min-width="100"
                    />
                    <el-table-column
                        prop="distributorName"
                        :label="$t('pageKey248', '分销商名称')"
                        min-width="100"
                        v-if="selectDialog.type === 2"
                    />
                    <el-table-column
                        prop="distributorTel"
                        :label="$t('pageKey249', '分销商电话')"
                        min-width="100"
                        v-if="selectDialog.type === 2"
                    />
                    <el-table-column
                        :label="$t('pageKey473', '注册时间')"
                        min-width="150"
                    >
                        <template slot-scope="scope">
                            {{ scope.row.registerTime | moment('YYYY-MM-DD HH:mm:ss') }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="buyerStatusName"
                        :label="$t('pageKey468', '买家状态')"
                        min-width="100"
                    />
                    <el-table-column
                        fixed="right"
                        :label="$t('pageKey13', '操作')"
                        width="90"
                    >
                        <template slot-scope="scope">
                            <el-button
                                type="primary"
                                size="small"
                                @click="selectRow(scope.row)"
                            >
                                {{ $t('pageKey498', '选择') }}
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <!-- 分页 -->
                <div class="ma-t ta-r">
                    <pagination
                        v-model="selectDialog.pagination"
                        @input="onSelectDialogQuery"
                    />
                </div>
            </div>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <!--<el-button-->
                <!--type="primary"-->
                <!--size="small"-->
                <!--@click="onSelectConfirm"-->
                <!--&gt;确 定-->
                <!--</el-button>-->
                <el-button
                    size="small"
                    @click="onSelectCancel"
                >
                    {{ $t('pageKey20', '取 消') }}
                </el-button>
            </div>
        </el-dialog>

        <!--创建订单-->
        <el-dialog
            :title="$t('pageKey499', '创建订单')"
            center
            width="1200px"
            :visible.sync="createOrderDialog.isVisible"
            @closed="onCreateOrderDialogCancel"
        >
            <el-form
                ref="createOrderForm"
                size="small"
                :model="createOrderDialog.formModel"
                :rules="createOrderDialog.formRules"
            >
                <el-form-item
                    :label="$t('pageKey500', '商品列表')"
                    label-width="9em"
                >
                    <div
                        class="ma-b"
                    >
                        <el-button-group>
                            <el-button
                                type="success"
                                icon="el-icon-plus"
                                size="small"
                                @click="onAddSelectGoods"
                            >
                                {{ $t('pageKey328', '选择商品') }}
                            </el-button>
                        </el-button-group>
                    </div>
                    <!-- 表格 -->
                    <el-table
                        stripe
                        border
                        size="small"
                        :data="selectGoodsDataList"
                        style="width: 100%"
                    >
                        <el-table-column
                            :label="$t('pageKey215', '商品图片')"
                            width="120"
                        >
                            <template slot-scope="scope">
                                <ImageList
                                    :data="scope.row.specImageUrl"
                                />
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="goodsName"
                            :label="$t('pageKey204', '商品名称')"
                            min-width="100"
                        />
                        <el-table-column
                            prop="goodsSn"
                            :label="$t('pageKey202', '商品编号')"
                            min-width="100"
                        />
                        <el-table-column
                            prop="specDesc"
                            :label="$t('pageKey254', '商品规格')"
                            min-width="100"
                        />
                        <el-table-column
                            prop="price"
                            :label="$t('pageKey243', '价格')"
                            min-width="50"
                        />
                        <el-table-column
                            prop="showStock"
                            :label="$t('pageKey218', '库存')"
                            min-width="50"
                        />
                        <el-table-column
                            prop="quantity"
                            :label="$t('pageKey501', '购买数量')"
                            min-width="100"
                        >
                            <template slot-scope="scope">
                                <el-input-number
                                    controls-position="right"
                                    :min="1"
                                    :precision="0"
                                    v-model.number="scope.row.quantity"
                                />
                            </template>
                        </el-table-column>
                        <el-table-column
                            fixed="right"
                            :label="$t('pageKey13', '操作')"
                            width="45"
                        >
                            <template slot-scope="scope">
                                <el-button
                                    type="text"
                                    size="small"
                                    @click="onDeleteSelectGoods(scope.row)"
                                >
                                    {{ $t('pageKey17', '删除') }}
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
                <el-form-item
                    prop="deliveryConsignee"
                    :label="$t('pageKey116', '收件人姓名')"
                    label-width="9em"
                >
                    <el-input
                        v-model="createOrderDialog.formModel.deliveryConsignee"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="deliveryPhone"
                    :label="$t('pageKey117', '收件人电话')"
                    label-width="9em"
                >
                    <el-input
                        v-model="createOrderDialog.formModel.deliveryPhone"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="deliveryRegionId"
                    :label="$t('pageKey502', '收件人地址')"
                    label-width="9em"
                >
                    <CascaderPicker
                        :api-class="selectApi"
                        v-model="createOrderDialog.formModel.deliveryRegionId"
                        :p-id="2"
                        :min-lv="2"
                        :placeholder="$t('pageKey101', '请选择')"
                    />
                </el-form-item>
                <el-form-item
                    prop="deliveryAddress"
                    :label="$t('pageKey503', '收件人详细地址')"
                    label-width="9em"
                >
                    <el-input
                        v-model="createOrderDialog.formModel.deliveryAddress"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="deliveryIdCardNo"
                    :label="$t('pageKey504', '收件人身份证')"
                    label-width="9em"
                >
                    <el-input
                        v-model="createOrderDialog.formModel.deliveryIdCardNo"
                        auto-complete="off"
                    />
                </el-form-item>
                <!--<el-form-item
prop="subscriberName"
label="付款人姓名"
label-width="9em"
>
<el-input
v-model="createOrderDialog.formModel.subscriberName"
auto-complete="off"
/>
</el-form-item>
<el-form-item
prop="subscriberIdCardNo"
label="付款人身份证号"
label-width="9em"
>
<el-input
v-model="createOrderDialog.formModel.subscriberIdCardNo"
auto-complete="off"
/>
</el-form-item>-->
                <el-form-item
                    prop="sendConsignor"
                    :label="$t('pageKey505', '发件人姓名')"
                    label-width="9em"
                >
                    <el-input
                        v-model="createOrderDialog.formModel.sendConsignor"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="sendTel"
                    :label="$t('pageKey506', '发件人电话')"
                    label-width="9em"
                >
                    <el-input
                        v-model="createOrderDialog.formModel.sendTel"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="buyerMsg"
                    :label="$t('pageKey507', '买家留言')"
                    label-width="9em"
                >
                    <el-input
                        type="textarea"
                        v-model="createOrderDialog.formModel.buyerMsg"
                        auto-complete="off"
                    />
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onCreateOrderDialogConfirm"
                >
                    {{ $t('pageKey19', '确 定') }}
                </el-button>
                <el-button
                    size="small"
                    @click="onCreateOrderDialogCancel"
                >
                    {{ $t('pageKey20', '取 消') }}
                </el-button>
            </div>
        </el-dialog>

        <!-- 选择商品 -->
        <el-dialog
            :title="$t('pageKey328', '选择商品')"
            center
            width="1200px"
            :visible.sync="selectGoodsDialog.isVisible"
            @closed="onSelectGoodsCancel"
        >
            <!-- 表单区 -->
            <el-form
                ref="selectGoodsDialogQueryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="selectGoodsDialog.queryFormModel"
            >
                <el-form-item
                    prop="goodsName"
                    :label="$t('pageKey204', '商品名称')"
                >
                    <el-input
                        v-model="selectGoodsDialog.queryFormModel.goodsName"
                        :placeholder="$t('pageKey205', '请输入商品名称')"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsSn"
                    :label="$t('pageKey202', '商品编号')"
                >
                    <el-input
                        v-model="selectGoodsDialog.queryFormModel.goodsSn"
                        :placeholder="$t('pageKey203', '请输入商品编号')"
                    />
                </el-form-item>
                <el-form-item
                    prop="specDesc"
                    :label="$t('pageKey254', '商品规格')"
                >
                    <el-input
                        v-model="selectGoodsDialog.queryFormModel.specDesc"
                        :placeholder="$t('pageKey508', '请输入商品规格')"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onSelectGoodsDialogQuery()"
                    >
                        {{ $t('pageKey9', '查询') }}
                    </el-button>
                    <el-button
                        type="success"
                        @click="onResetSelectGoodsDialog"
                    >
                        {{ $t('pageKey10', '重置') }}
                    </el-button>
                </el-form-item>
            </el-form>

            <!-- 内容 -->
            <div class="body">
                <!-- 表格 -->
                <el-table
                    stripe
                    border
                    size="small"
                    :data="selectGoodsDialog.tableData"
                    style="width: 100%"
                    height="400"
                    row-key="goodsId"
                    ref="selectGoodsDialogTable"
                    @selection-change="onSelectGoodsDialogSelectionChange"
                >
                    <el-table-column
                        type="selection"
                        :reserve-selection="true"
                        width="36"
                    />
                    <el-table-column
                        :label="$t('pageKey215', '商品图片')"
                        width="120"
                    >
                        <template slot-scope="scope">
                            <ImageList
                                :data="scope.row.specImageUrl"
                            />
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="goodsName"
                        :label="$t('pageKey204', '商品名称')"
                        min-width="100"
                    />
                    <el-table-column
                        prop="goodsSn"
                        :label="$t('pageKey202', '商品编号')"
                        min-width="100"
                    />
                    <el-table-column
                        prop="specDesc"
                        :label="$t('pageKey254', '商品规格')"
                        min-width="100"
                    />
                    <el-table-column
                        prop="price"
                        :label="$t('pageKey243', '价格')"
                        min-width="50"
                    />
                    <el-table-column
                        prop="showStock"
                        :label="$t('pageKey218', '库存')"
                        min-width="50"
                    />
                </el-table>

                <!-- 分页 -->
                <div class="ma-t ta-r">
                    <pagination
                        v-model="selectGoodsDialog.pagination"
                        @input="onSelectGoodsDialogQuery"
                    />
                </div>
            </div>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onSelectGoodsConfirm"
                >
                    {{ $t('pageKey19', '确 定') }}
                </el-button>
                <el-button
                    size="small"
                    @click="onSelectGoodsCancel"
                >
                    {{ $t('pageKey20', '取 消') }}
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'Buyer',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                nickName: '', // 昵称
                mobile: '', // 电话
                buyerStatus: '', // 会员状态
                buyerLevelId: '', // 会员等级
                insertTime: '',
                referenceBuyer: '',
                comments: '',
            },
            setLevelDialog: {
                // 对话框显示
                isVisible: false,
                formModel: {
                    ids: '',
                    buyerLevelId: '',
                },
                // 表单校验规则
                formRules: {
                    buyerLevelId: {
                        required: true,
                        message: this.$t('pageKey509', '请选择会员等级'),
                    },
                },
            },
            editInfoDialog: {
                // 对话框显示
                isVisible: false,
                formModel: {
                    id: '',
                    buyerLevelId: '',
                    referenceId: '',
                    comments: '',
                },
                // 表单校验规则
                formRules: {
                    buyerLevelId: {
                        required: true,
                        message: this.$t('pageKey509', '请选择会员等级'),
                    },
                },
                referenceBuyer: {},
            },
            setFrontDecorationDialog: {
                // 对话框显示
                isVisible: false,
                formModel: {
                    ids: '',
                    firstPageDecorationId: '',
                },
                // 表单校验规则
                formRules: {},
            },
            orderImportDialog: {
                // 对话框显示
                isVisible: false,
                buyer: {},
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
            // 等级列表
            buyerLevelList: [],
            shopDecorationList: [],
            buyerPrepaidDialog: {
                // 对话框显示
                isVisible: false,
                formModel: {
                    buyerId: '',
                    prepaidAmount: '',
                    cardPrice: '',
                    payVoucher: [],
                    paymentComments: '',
                },
                // 表单校验规则
                formRules: {
                    prepaidAmount: {
                        required: true,
                        message: this.$t('pageKey510', '请输入充值金额'),
                        trigger: 'blur',
                    },
                    cardPrice: {
                        required: true,
                        message: this.$t('pageKey511', '请输入实付金额'),
                        trigger: 'blur',
                    },
                    payVoucher: {
                        type: 'array',
                        required: true,
                        message: this.$t('pageKey512', '请上传付款凭证'),
                    },
                },
            },
            // 新增/编辑
            selectDialog: {
                // 对话框显示
                isVisible: false,
                // 查询数据
                queryFormModel: {
                    userName: '',
                    nickName: '', // 昵称
                    mobile: '', // 电话
                    isDistributor: '',
                },
                // 表单数据
                tableData: [],
                // 分页
                pagination: {},
                type: '',
            },
            createOrderDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    buyerId: '',
                    deliveryConsignee: '',
                    deliveryPhone: '',
                    deliveryRegionId: '',
                    deliveryAddress: '',
                    deliveryIdCardNo: '',
                    subscriberName: '',
                    subscriberIdCardNo: '',
                    sendConsignor: '',
                    sendTel: '',
                    buyerMsg: '',
                },
                // 表单校验规则
                formRules: {
                    deliveryConsignee: {
                        required: true,
                        message: this.$t('pageKey137', '请输入收件人姓名'),
                        trigger: 'blur',
                    },
                    deliveryPhone: {
                        required: true,
                        message: this.$t('pageKey138', '请输入收件人电话'),
                        trigger: 'blur',
                    },
                    deliveryRegionId: {
                        required: true,
                        message: this.$t('pageKey513', '请选择收件人地址'),
                    },
                    deliveryAddress: {
                        required: true,
                        message: this.$t('pageKey514', '请输入收件人详细地址'),
                        trigger: 'blur',
                    },
                },
            },
            // 商品弹窗
            selectGoodsDialog: {
                // 对话框显示
                isVisible: false,
                // 查询数据
                queryFormModel: {
                    goodsName: '', // 商品名称
                    goodsSn: '', // 商品编号
                    specDesc: '',
                    goodsStatusType: '1',
                },
                // 表单数据
                tableData: [],
                // 分页
                pagination: {},
                // 已选择数据
                currentTableSelect: [],
            },
            // 商品表格数据
            selectGoodsDataList: [],
            selectApi: this.$api.Rs.Region,
            platEditPointDialog: {
                // 对话框显示
                isVisible: false,
                formModel: {
                    buyerId: '',
                    pointNumber: '',
                    pointDesc: '',
                },
                // 表单校验规则
                formRules: {
                    pointNumber: {
                        required: true,
                        message: this.$t('pageKey515', '请输入积分变动值'),
                        trigger: 'blur',
                    },
                    pointDesc: {
                        required: true,
                        message: this.$t('pageKey516', '请输入积分变动描述'),
                        trigger: 'blur',
                    },
                },
            },
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Mb.Buyer.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        /* -- 事件 -- */
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        onSetLevelDialogConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$api.Mb.Buyer.changeBuyerLevel({
                    ...this.setLevelDialog.formModel,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                    this.setLevelDialog.isVisible = false;
                });
            });
        },
        onSetLevelDialogCancel() {
            const { addForm } = this.$refs;
            addForm.resetFields();
            this.setLevelDialog.isVisible = false;
        },
        onChangeBuyerLevel(row) {
            this.setLevelDialog.formModel.ids = row.id;
            this.setLevelDialog.isVisible = true;
        },
        initLevelList() {
            this.$api.Mb.BuyerLevel.select().then(json => {
                const res = json.data.data;
                this.buyerLevelList = res;
            });
        },
        onBatchChangeBuyerLevel() {
            if (this.currentTableSelect.length === 0) {
                this.$message({
                    message: this.$t('pageKey517', '请选择会员'),
                    type: 'error',
                });
                return;
            }
            const changeBuyerIdList = [];
            this.currentTableSelect.forEach(item => {
                changeBuyerIdList.push(item.id);
            });
            this.setLevelDialog.formModel.ids = changeBuyerIdList.join(',');
            this.setLevelDialog.isVisible = true;
        },
        onSetFrontDecorationDialogConfirm() {
            const { frontDecorationForm } = this.$refs;
            frontDecorationForm.validate().then(() => {
                this.$api.Mb.Buyer.changeFrontDecoration({
                    ...this.setFrontDecorationDialog.formModel,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                    this.setFrontDecorationDialog.isVisible = false;
                });
            });
        },
        onSetFrontDecorationDialogCancel() {
            const { frontDecorationForm } = this.$refs;
            frontDecorationForm.resetFields();
            this.setFrontDecorationDialog.isVisible = false;
        },
        onBatchChangeFrontDecoration() {
            if (this.currentTableSelect.length === 0) {
                this.$message({
                    message: this.$t('pageKey517', '请选择会员'),
                    type: 'error',
                });
                return;
            }
            const changeBuyerIdList = [];
            this.currentTableSelect.forEach(item => {
                changeBuyerIdList.push(item.id);
            });
            this.setFrontDecorationDialog.formModel.ids = changeBuyerIdList.join(',');
            this.setFrontDecorationDialog.isVisible = true;
        },
        onChangeFrontDecoration(row) {
            this.setFrontDecorationDialog.formModel.ids = row.id;
            if (row.firstPageDecorationId) {
                this.setFrontDecorationDialog.formModel.firstPageDecorationId = `${row.firstPageDecorationId}`;
            } else {
                this.setFrontDecorationDialog.formModel.firstPageDecorationId = '';
            }
            this.setFrontDecorationDialog.isVisible = true;
        },
        initShopDecorationList() {
            this.$api.Sp.ShopDecoration.select().then(json => {
                const res = json.data.data;
                this.shopDecorationList = res;
            });
        },
        onOrderImport(row) {
            this.orderImportDialog.buyer = row;
            this.orderImportDialog.isVisible = true;
        },
        onOrderImportDialogCancel() {
            this.orderImportDialog.isVisible = false;
        },
        onOrderImportSuccess(response) {
            if (response.success) {
                this.$message({
                    message: response.msg,
                    type: 'success',
                });
            } else {
                this.$alert(response.msg, this.$t('pageKey518', '错误提示'), {
                    dangerouslyUseHTMLString: true,
                    confirmButtonText: this.$t('pageKey19', '确定'),
                });
            }
        },
        onOrderImportError(err) {
            this.$message({
                message: err,
                type: 'error',
            });
        },
        onBuyerPrepaidDialogConfirm() {
            const { buyerPrepaidForm } = this.$refs;
            buyerPrepaidForm.validate().then(() => {
                this.$api.Mb.Buyer.buyerPrepaidPayment({
                    ...this.buyerPrepaidDialog.formModel,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    // this.onQuery(this.pagination);
                    this.buyerPrepaidDialog.isVisible = false;
                });
            });
        },
        onBuyerPrepaidDialogCancel() {
            const { buyerPrepaidForm } = this.$refs;
            buyerPrepaidForm.resetFields();
            this.buyerPrepaidDialog.isVisible = false;
        },
        onBuyerPrepaid(row) {
            this.buyerPrepaidDialog.formModel.buyerId = row.id;
            this.buyerPrepaidDialog.isVisible = true;
        },
        onEditInfoDialogConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$api.Mb.Buyer.editBuyerInfo({
                    ...this.editInfoDialog.formModel,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                    this.editInfoDialog.isVisible = false;
                });
            });
        },
        onEditInfoDialogCancel() {
            const { addForm } = this.$refs;
            addForm.resetFields();
            this.editInfoDialog.isVisible = false;
        },
        onEdit(row) {
            this.editInfoDialog.formModel.id = row.id;
            this.editInfoDialog.referenceBuyer.id = row.referenceId;
            this.editInfoDialog.referenceBuyer.distributorName = row.referenceDistributorName;
            this.editInfoDialog.formModel.buyerLevelId = `${row.buyerLevelId}`;
            this.editInfoDialog.formModel.referenceId = row.referenceId;
            this.editInfoDialog.formModel.comments = row.comments;
            this.editInfoDialog.isVisible = true;
        },
        onAddSelect(type) {
            this.selectDialog.type = type;
            if (type === 1) {
                this.selectDialog.queryFormModel.isDistributor = 0;
            } else {
                this.selectDialog.queryFormModel.isDistributor = 1;
            }
            this.selectDialog.isVisible = true;
            this.$nextTick(() => {
                this.onSelectDialogQuery();
            });
        },
        onSelectDialogQuery(pagination) {
            return this.$api.Mb.Buyer.selectByPage({
                ...this.selectDialog.queryFormModel,
                ...pagination,
                idNE: this.editInfoDialog.formModel.id,
            }).then(json => {
                const res = json.data;
                this.selectDialog.tableData = res.data;
                this.selectDialog.pagination = res.pagination;
            });
        },
        onResetSelectDialog() {
            this.$refs.selectDialogQueryForm.resetFields();
        },
        onSelectCancel() {
            this.selectDialog.isVisible = false;
        },
        selectRow(row) {
            if (this.selectDialog.type === 1) {
                this.$utils.formModelMerge(this.formModel, row);
                this.editInfoDialog.formModel.referenceId = row.id;
            } else {
                this.editInfoDialog.referenceBuyer = row;
                this.editInfoDialog.formModel.referenceId = row.id;
            }
            this.selectDialog.isVisible = false;
        },
        // 创建订单
        onCreateOrder(row) {
            this.selectGoodsDataList = [];
            this.createOrderDialog.formModel.buyerId = row.id;
            this.createOrderDialog.isVisible = true;
            this.$nextTick(() => {
                const { createOrderForm } = this.$refs;
                createOrderForm.resetFields();
                const { formModel } = this.createOrderDialog;
                formModel.id = '';
            });
        },
        onCreateOrderDialogConfirm() {
            const { createOrderForm } = this.$refs;
            createOrderForm.validate().then(() => {
                this.$api.Ex.Order.createOrder({
                    ...this.createOrderDialog.formModel,
                    goodsListJson: JSON.stringify(this.selectGoodsDataList),
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                    this.createOrderDialog.isVisible = false;
                });
            });
        },
        onCreateOrderDialogCancel() {
            const { createOrderForm } = this.$refs;
            createOrderForm.resetFields();
            const { formModel } = this.createOrderDialog;
            formModel.id = '';
            this.createOrderDialog.isVisible = false;
        },
        /* -- 事件 -- */
        onAddSelectGoods() {
            this.selectGoodsDialog.isVisible = true;
            this.$nextTick(() => {
                this.onSelectGoodsDialogQuery();
                // 初始化已选择数据
                if (this.$refs.selectGoodsDialogTable) {
                    this.$refs.selectGoodsDialogTable.clearSelection();
                }
                this.selectGoodsDataList.forEach(item => {
                    this.$refs.selectGoodsDialogTable.toggleRowSelection(item, true);
                });
            });
        },
        onSelectGoodsDialogQuery(pagination) {
            return this.$api.Gd.Goods.findSkuMapList({
                ...this.selectGoodsDialog.queryFormModel,
                ...pagination,
            }).then(json => {
                const res = json.data;
                this.selectGoodsDialog.tableData = res.data;
                this.selectGoodsDialog.pagination = res.pagination;
            });
        },
        onSelectGoodsDialogSelectionChange(val) {
            this.selectGoodsDialog.currentTableSelect = val;
        },
        onResetSelectGoodsDialog() {
            this.$refs.selectGoodsDialogQueryForm.resetFields();
        },
        onSelectGoodsConfirm() {
            // 插入新数据
            const list = [];
            this.selectGoodsDialog.currentTableSelect.forEach(item => {
                let isHave = false;
                this.selectGoodsDataList.forEach(oldItem => {
                    if (item.goodsId === oldItem.goodsId) {
                        list.push({ ...oldItem });
                        isHave = true;
                    }
                });
                if (!isHave) {
                    // 添加新数据
                    item.id = null;
                    list.push({ ...item });
                }
            });
            this.selectGoodsDataList = [...list];
            this.selectGoodsDialog.isVisible = false;
        },
        onSelectGoodsCancel() {
            this.selectGoodsDialog.isVisible = false;
        },
        onDeleteSelectGoods(row) {
            this.selectGoodsDataList.splice(this.selectGoodsDataList.indexOf(row), 1);
        },
        onSyncBuyer() {
            this.$confirm(this.$t('pageKey519', '是否确定要同步会员!'), this.$t('pageKey42', '温馨提示'), {
                type: 'warning',
                confirmButtonText: this.$t('pageKey19', '确定'),
                cancelButtonText: this.$t('pageKey20', '取消'),
            }).then(() => {
                this.$api.Rs.Pospal.syncBuyer().then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
        exportExcel() {
            let ids = '';
            if (this.currentTableSelect.length !== 0) {
                const idList = [];
                this.currentTableSelect.forEach(item => {
                    idList.push(item.id);
                });
                ids = idList.join(',');
            }

            let insertBeginTime = null;
            let insertEndTime = null;
            if (this.queryFormModel.insertTime != null && this.queryFormModel.insertTime.length === 2) {
                [insertBeginTime, insertEndTime] = this.queryFormModel.insertTime;
            }
            this.$http.href('/mb/buyer/exportExcel', {
                ...this.queryFormModel,
                insertBeginTime,
                insertEndTime,
                ids,
            });
        },
        // 积分变更
        onPlatEditPointDialogConfirm() {
            const { platEditPointForm } = this.$refs;
            platEditPointForm.validate().then(() => {
                this.$api.Mb.Buyer.platEditPoint({
                    ...this.platEditPointDialog.formModel,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                    this.platEditPointDialog.isVisible = false;
                });
            });
        },
        onPlatEditPointDialogCancel() {
            const { platEditPointForm } = this.$refs;
            platEditPointForm.resetFields();
            this.platEditPointDialog.isVisible = false;
        },
        onPlatEditPoint(row) {
            this.platEditPointDialog.formModel.buyerId = row.id;
            this.platEditPointDialog.isVisible = true;
        },
    },
    created() {
        this.initLevelList();
        this.initShopDecorationList();
    },
};
</script>

<style lang="scss">
</style>
