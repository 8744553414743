<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="levelName"
                    :label="$t('pageKey537', '等级名称')"
                >
                    <el-input
                        v-model="queryFormModel.levelName"
                        :placeholder="$t('pageKey538', '请输入等级名称')"
                    />
                </el-form-item>
                <el-form-item
                    prop="isAvailable"
                    :label="$t('pageKey67', '状态')"
                >
                    <el-select
                        v-model="queryFormModel.isAvailable"
                        :placeholder="$t('pageKey101', '请选择')"
                    >
                        <el-option
                            :label="$t('pageKey6', '全部')"
                            value=""
                        />
                        <el-option
                            :label="$t('pageKey7', '启用')"
                            value="1"
                        />
                        <el-option
                            :label="$t('pageKey8', '禁用')"
                            value="0"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        {{ $t('pageKey9', '查询') }}
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        {{ $t('pageKey10', '重置') }}
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="onAdd"
                    >
                        {{ $t('pageKey15', '新增') }}
                    </el-button>
                    <el-button
                        icon="el-icon-refresh-right"
                        type="success"
                        size="small"
                        @click="onSyncBuyerLevel"
                        v-if="userData.shop.isSyncPospal"
                    >
                        {{ $t('pageKey539', '同步银豹会员等级') }}
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
                :empty-text="$t('pageKey977', '暂无数据')"
            >
                <el-table-column
                    prop="levelIcon"
                    :label="$t('pageKey540', '等级图标')"
                    width="120"
                >
                    <template slot-scope="scope">
                        <ImageList
                            :data="scope.row.levelIconUrl"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    prop="levelName"
                    :label="$t('pageKey537', '等级名称')"
                    min-width="100"
                />
                <el-table-column
                    prop="levelCode"
                    :label="$t('pageKey541', '等级代码')"
                    min-width="100"
                />
                <el-table-column
                    prop="levelDesc"
                    :label="$t('pageKey542', '等级描述')"
                    min-width="100"
                />
                <el-table-column
                    prop="orderNum"
                    :label="$t('pageKey11', '排序号')"
                    min-width="100"
                />
                <el-table-column
                    prop="isAvailable"
                    :label="$t('pageKey12', '是否可用')"
                    min-width="100"
                >
                    <template slot-scope="scope">
                        <el-switch
                            v-model="scope.row.isAvailable"
                            @input="onIsAvailableInput($event,scope)"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    prop="isDefault"
                    :label="$t('pageKey170', '是否默认')"
                    min-width="100"
                >
                    <template slot-scope="scope">
                        <el-switch
                            v-model="scope.row.isDefault"
                            @input="onIsDefaultInput($event,scope)"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    :label="$t('pageKey55', '创建时间')"
                    width="135"
                >
                    <template slot-scope="scope">
                        {{ scope.row.insertTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    :label="$t('pageKey57', '修改时间')"
                    width="135"
                >
                    <template slot-scope="scope">
                        {{ scope.row.updateTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    :label="$t('pageKey13', '操作')"
                    width="90"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableEdit(scope.row)"
                        >
                            {{ $t('pageKey16', '编辑') }}
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableDelete(scope.row.id)"
                        >
                            {{ $t('pageKey17', '删除') }}
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>

        <!-- 弹框 -->
        <!-- 新增/编辑 -->
        <el-dialog
            :title="addDialog.formModel.id ? $t('pageKey16', '编辑') : $t('pageKey15', '新增')"
            center
            width="600px"
            :visible.sync="addDialog.isVisible"
            @closed="onAddDialogCancel"
        >
            <el-form
                ref="addForm"
                size="small"
                :model="addDialog.formModel"
                :rules="addDialog.formRules"
            >
                <el-form-item
                    prop="levelName"
                    :label="$t('pageKey537', '等级名称')"
                    label-width="9em"
                >
                    <el-input
                        v-model="addDialog.formModel.levelName"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="levelCode"
                    :label="$t('pageKey541', '等级代码')"
                    label-width="9em"
                >
                    <el-input
                        v-model="addDialog.formModel.levelCode"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="levelIcon"
                    :label="$t('pageKey540', '等级图标')"
                    label-width="9em"
                >
                    <upload
                        validate-md5
                        action="/rs/attachment/uploadFileLibrary"
                        :limit="1"
                        v-model="addDialog.formModel.levelIcon"
                    />
                </el-form-item>
                <el-form-item
                    prop="onlinePaymentType"
                    :label="$t('pageKey171', '付款方式')"
                    label-width="9em"
                >
                    <checkbox-group-andOr
                        :data="paymentTypeList"
                        v-model="addDialog.formModel.onlinePaymentType"
                    />
                </el-form-item>
                <el-form-item
                    prop="shipMethod"
                    :label="$t('pageKey543', '配送方式')"
                    label-width="9em"
                >
                    <checkbox-group-andOr
                        :data="shipMethodList"
                        v-model="addDialog.formModel.shipMethod"
                    />
                </el-form-item>
                <el-form-item
                    prop="levelDesc"
                    :label="$t('pageKey542', '等级描述')"
                    label-width="9em"
                >
                    <el-input
                        type="textarea"
                        v-model="addDialog.formModel.levelDesc"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="orderNum"
                    :label="$t('pageKey11', '排序')"
                    label-width="9em"
                >
                    <el-input-number
                        controls-position="right"
                        :min="1"
                        v-model.number="addDialog.formModel.orderNum"
                    />
                </el-form-item>
                <el-form-item
                    prop="isGainGrowthValue"
                    :label="$t('pageKey544', '是否获取成长值')"
                    label-width="9em"
                    v-if="(userData.shop.buyerConfig & 2) != 0"
                >
                    <el-switch
                        v-model="addDialog.formModel.isGainGrowthValue"
                    />
                </el-form-item>
                <el-form-item
                    prop="isGrowthValueUpgrade"
                    :label="$t('pageKey545', '是否可升级')"
                    label-width="9em"
                    v-if="(userData.shop.buyerConfig & 2) != 0"
                >
                    <el-switch
                        v-model="addDialog.formModel.isGrowthValueUpgrade"
                    />
                </el-form-item>
                <el-form-item
                    prop="gradeGrowthValue"
                    :label="$t('pageKey546', '等级成长值')"
                    label-width="9em"
                    v-if="addDialog.formModel.isGrowthValueUpgrade && (userData.shop.buyerConfig & 2) != 0"
                >
                    <el-input-number
                        controls-position="right"
                        :min="0"
                        :precision="0"
                        v-model.number="addDialog.formModel.gradeGrowthValue"
                    />
                    {{ $t('pageKey547', '到达该成长值，可升级为该等级') }}
                </el-form-item>
                <el-form-item
                    prop="growthValueRatio"
                    :label="$t('key548', '成长值获取比例')"
                    label-width="9em"
                    v-if="addDialog.formModel.isGainGrowthValue && (userData.shop.buyerConfig & 2) != 0"
                >
                    <el-input-number
                        controls-position="right"
                        :min="0"
                        :precision="2"
                        v-model.number="addDialog.formModel.growthValueRatio"
                    />
                    %
                </el-form-item>
                <el-form-item
                    prop="buyerDiscount"
                    :label="$t('key549', '会员折扣')"
                    label-width="9em"
                    v-if="(userData.shop.buyerConfig & 4) != 0"
                >
                    <el-input-number
                        controls-position="right"
                        :min="0"
                        :precision="2"
                        v-model.number="addDialog.formModel.buyerDiscount"
                    />
                    %
                </el-form-item>
                <el-form-item
                    prop="isDefault"
                    :label="$t('pageKey170', '是否默认')"
                    label-width="9em"
                >
                    <el-switch
                        v-model="addDialog.formModel.isDefault"
                    />
                </el-form-item>
                <el-form-item
                    prop="isAvailable"
                    :label="$t('pageKey12', '是否可用')"
                    label-width="9em"
                >
                    <el-switch
                        v-model="addDialog.formModel.isAvailable"
                    />
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onAddDialogConfirm"
                >
                    {{ $t('pageKey19', '确 定') }}
                </el-button>
                <el-button
                    size="small"
                    @click="onAddDialogCancel"
                >
                    {{ $t('pageKey20', '取 消') }}
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'BuyerLevel',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                levelName: '', // 等级名称
                isAvailable: '', // 是否可用
            },
            // 新增/编辑
            addDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    id: '',
                    levelName: '',
                    levelCode: '',
                    levelDesc: '',
                    levelIcon: [],
                    fileBasePath: '',
                    orderNum: '',
                    isAvailable: true,
                    isDefault: false,
                    onlinePaymentType: '',
                    shipMethod: '',
                    isGainGrowthValue: false,
                    isGrowthValueUpgrade: false,
                    gradeGrowthValue: '0',
                    growthValueRatio: 100,
                    buyerDiscount: 100,
                },
                // 表单校验规则
                formRules: {
                    levelName: {
                        required: true,
                        message: this.$t('pageKey538', '请输入等级名称'),
                        trigger: 'blur',
                    },
                    levelIcon: {
                        type: 'array',
                        required: true,
                        message: this.$t('pageKey158', '请上传等级图片'),
                    },
                    gradeGrowthValue: {
                        required: true,
                        message: this.$t('pageKey550', '请输入等级成长值'),
                        trigger: 'blur',
                    },
                    growthValueRatio: {
                        required: true,
                        message: this.$t('pageKey551', '请输入成长值获取比例'),
                        trigger: 'blur',
                    },
                    isGainGrowthValue: {
                        required: true,
                        message: this.$t('pageKey238', '请输入'),
                    },
                    isGrowthValueUpgrade: {
                        required: true,
                        message: this.$t('pageKey238', '请输入'),
                    },
                    buyerDiscount: {
                        required: true,
                        message: this.$t('pageKey238', '请输入'),
                    },
                },
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
            paymentTypeList: [],
            shipMethodList: [],
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Mb.BuyerLevel.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        /* -- 事件 -- */
        onAdd() {
            this.addDialog.isVisible = true;
            this.$nextTick(() => {
                const { addForm } = this.$refs;
                addForm.resetFields();
                const { formModel } = this.addDialog;
                formModel.id = '';
            });
        },
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        onTableEdit(row) {
            const { formModel } = this.addDialog;
            this.$api.Mb.BuyerLevel.getDetail({ id: row.id }).then(json => {
                const res = json.data.data;
                this.$utils.formModelMerge(formModel, res);
            });
            this.addDialog.isVisible = true;
        },
        onTableDelete(ids) {
            this.$confirm(this.$t('pageKey41', '是否确定要删除!'), this.$t('pageKey42', '温馨提示'), {
                type: 'warning',
                confirmButtonText: this.$t('pageKey19', '确定'),
                cancelButtonText: this.$t('pageKey20', '取消'),
            }).then(() => {
                this.$api.Mb.BuyerLevel.delete({ ids }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
        onDelete() {
            const ids = this.currentTableSelect.reduce((prev, next) => {
                prev.push(next.id);
                return prev;
            }, []);
            this.onTableDelete(ids.join(','));
        },
        onAddDialogConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$api.Mb.BuyerLevel.save({
                    ...this.addDialog.formModel,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                    this.addDialog.isVisible = false;
                });
            });
        },
        onAddDialogCancel() {
            const { addForm } = this.$refs;
            addForm.resetFields();
            const { formModel } = this.addDialog;
            formModel.id = '';
            this.addDialog.isVisible = false;
        },
        onIsAvailableInput(val, scope) {
            const { row } = scope;
            if (!row) return;
            this.$api.Mb.BuyerLevel.status({
                id: row.id,
                isAvailable: row.isAvailable,
            })
                .then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                })
                .catch(() => {
                    this.$message({
                        message: this.$t('pageKey43', '操作失败请重试'),
                        type: 'error',
                    });
                    row.isAvailable = !row.isAvailable;
                });
        },
        onIsDefaultInput(val, scope) {
            const { row } = scope;
            if (!row) return;
            this.$api.Mb.BuyerLevel.isDefault({
                id: row.id,
                isDefault: row.isDefault,
            })
                .then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                })
                .catch(() => {
                    this.$message({
                        message: this.$t('pageKey43', '操作失败请重试'),
                        type: 'error',
                    });
                    row.isDefault = !row.isDefault;
                });
        },
        initPaymentTypeList() {
            this.$api.Sp.Shop.getShopOnlinePaymentTypeList().then(json => {
                this.paymentTypeList = json.data.data;
            });
        },
        initShipMethodList() {
            this.$api.Sp.Shop.getShopShipMethodList({}).then(json => {
                this.shipMethodList = json.data.data;
            });
        },
        onSyncBuyerLevel() {
            this.$confirm(this.$t('pageKey552', '是否确定要同步会员等级!'), this.$t('pageKey42', '温馨提示'), {
                type: 'warning',
                confirmButtonText: this.$t('pageKey19', '确定'),
                cancelButtonText: this.$t('pageKey20', '取消'),
            }).then(() => {
                this.$api.Rs.Pospal.syncBuyerLevel().then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
    },
    created() {
        this.initPaymentTypeList();
        this.initShipMethodList();
    },
};
</script>

<style lang="scss">
</style>
