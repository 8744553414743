<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="userName"
                    label="用户名"
                >
                    <el-input
                        v-model="queryFormModel.userName"
                        placeholder="请输入用户名"
                    />
                </el-form-item>
                <el-form-item
                    prop="mobile"
                    label="手机号"
                >
                    <el-input
                        v-model="queryFormModel.mobile"
                        placeholder="请输入手机号"
                    />
                </el-form-item>
                <el-form-item
                    prop="promotionLevelId"
                    label="推广等级"
                >
                    <RemoteSelect
                        filterable
                        remote="/mb/promotionLevel/select"
                        :placeholder="$t('pageKey238', '请输入')"
                        :props="{id:'id',name:'levelName'}"
                        v-model="queryFormModel.promotionLevelId"
                    />
                </el-form-item>
                <el-form-item
                    prop="parentId"
                    label=""
                />
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
            >
                <el-table-column
                    prop="userName"
                    label="用户名"
                    min-width="100"
                />
                <el-table-column
                    prop="mobile"
                    label="手机号"
                    min-width="100"
                />
                <el-table-column
                    prop="promotionLevelName"
                    label="推广等级"
                    min-width="100"
                />
                <el-table-column
                    prop="subordinateNum"
                    label="下级数量"
                    min-width="100"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push('/mb/promotion/buyerReference?parentId=' + scope.row.id + '&currentPage=1')"
                        >
                            {{scope.row.subordinateNum}}
                        </el-button>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="groupNum"
                    label="团队数量"
                    min-width="100"
                />
                <el-table-column
                    prop="orderNum"
                    label="直推订单数"
                    min-width="100"
                />
                <el-table-column
                    prop="orderAmount"
                    label="直推订单金额"
                    min-width="100"
                />
                <el-table-column
                    prop="groupOrderNum"
                    label="团队订单数"
                    min-width="100"
                />
                <el-table-column
                    prop="groupOrderAmount"
                    label="团队订单金额"
                    min-width="100"
                />
                <el-table-column
                    prop="totalIncome"
                    label="总收益金额"
                    min-width="100"
                />
                <el-table-column
                    prop="frozenAmount"
                    label="冻结金额"
                    min-width="100"
                />
                <el-table-column
                    label="创建时间"
                    width="135"
                >
                    <template slot-scope="scope">
                        {{ scope.row.insertTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'Variable',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                userName: '', // 用户名
                mobile: '', // 手机号
                promotionLevelId: '',
                parentId: '',
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Mb.BuyerReference.list(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
    },
};
</script>

<style lang="scss">
</style>
